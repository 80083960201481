
import { UseModal } from '@/composable/useModal'
import QForm from 'quasar/src/components/form/QForm.js';
import { computed, defineComponent, inject, onBeforeMount, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import {
  useDeviationDelay,
  DeviationDelayModel,
} from '@/composable/useDeviationDelay'
import DeviationSearchTrainInput from '@/components/deviation/DeviationTrainSearchInput.vue'
import DeviationDescriptionInput from '@/components/deviation/DeviationDescriptionInput.vue'
import DeviationEventInput from '@/components/deviation/DeviationEventInput.vue'
import { useDeviationDelayProposal } from '@/composable/useDeviationDelayProposal'
import { format } from 'date-fns'

export default defineComponent({
  name: 'DeviationDelayFormModal',

  components: {
    DeviationSearchTrainInput,
    DeviationDescriptionInput,
    DeviationEventInput,
  },

  props: {
    eventUuid: {
      type: String,
    },
    showEventInput: Boolean,
  },

  setup(props) {
    const {
      add: addDeviationDelay,
      update: updateDeviationDelay,
      loading: loadingStateDeviationDelay,
    } = useDeviationDelay()
    const { data: deviationDelayProposals } = useDeviationDelayProposal()
    const formModal = inject<UseModal<DeviationDelayModel>>(
      'deviation-delay-form-modal'
    )
    const formRef = ref<QForm | null>(null)
    // const $q = useQuasar()

    const model = ref<DeviationDelayModel>({
      uuid: uuidv4(),
      departureDate: new Date(),
      delayMinutes: 0,
      delayLocation: '',
      delayAdvertisedTime: new Date(),
      delayType: 'ank',
      advertised: null,
      description: '',
      eventUuid: props.eventUuid as string,
      eventName: null,
      eventId: null,
      eventIsOpen: null,
      eventDate: null,
      delayReason: null,
      delayReasonDescription: null,
    })

    // If update modal set event to model
    onBeforeMount(() => {
      if (formModal?.state.data && formModal?.state.mode === 'update') {
        model.value.eventName = formModal.state.data.eventName
        model.value.uuid = formModal.state.data.uuid
        model.value.departureDate = formModal.state.data.departureDate
        model.value.advertised = formModal.state.data.advertised
        model.value.description = formModal.state.data.description
        model.value.eventUuid = formModal.state.data.eventUuid
        model.value.delayAdvertisedTime =
          formModal.state.data.delayAdvertisedTime
        model.value.delayLocation = formModal.state.data.delayLocation
        model.value.delayMinutes = formModal.state.data.delayMinutes
        model.value.delayType = formModal.state.data.delayType

        model.value.eventId = formModal.state.data.eventId
        model.value.eventIsOpen = formModal.state.data.eventIsOpen
        model.value.eventDate = formModal.state.data.eventDate
        model.value.delayReason = formModal.state.data.delayReason
        model.value.delayReasonDescription =
          formModal.state.data.delayReasonDescription
      }

      if (
        formModal?.state.data &&
        formModal?.state.mode === 'create-proposal'
      ) {
        model.value.advertised = formModal.state.data.advertised
        model.value.departureDate = formModal.state.data.departureDate
        model.value.delayLocation = formModal.state.data.delayLocation
        model.value.delayMinutes = formModal.state.data.delayMinutes
        model.value.delayType = formModal.state.data.delayType
        model.value.delayAdvertisedTime =
          formModal.state.data.delayAdvertisedTime
        model.value.delayReason = formModal.state.data.delayReason
        model.value.delayReasonDescription =
          formModal.state.data.delayReasonDescription
      }

      if (
        formModal?.state.data &&
        formModal?.state.mode === 'create' &&
        formModal.state.data.departureDate
      ) {
        model.value.departureDate = formModal.state.data.departureDate
      }
    })

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid) return Promise.reject()

      if (['create', 'create-proposal'].includes(formModal?.state.mode || '')) {
        await addDeviationDelay({
          ...model.value,
          bana: formModal?.state?.data?.bana || '-',
        })
        if (formModal?.state.mode === 'create-proposal') {
          deviationDelayProposals.value = deviationDelayProposals.value.filter(
            (x) =>
              !(
                x.advertised === model.value.advertised &&
                format(new Date(x.departureDate), 'yyyy-MM-dd') ===
                  (model.value.departureDate
                    ? format(new Date(model.value.departureDate), 'yyyy-MM-dd')
                    : '') &&
                x.delayType === model.value.delayType &&
                x.delayMinutes === model.value.delayMinutes &&
                x.delayLocation === model.value.delayLocation &&
                format(new Date(x.delayAdvertisedTime), 'yyyy-MM-dd HH:mm') ===
                  (model.value.delayAdvertisedTime
                    ? format(
                        new Date(model.value.delayAdvertisedTime),
                        'yyyy-MM-dd HH:mm'
                      )
                    : '')
              )
          )
          formModal?.closeModal()
          return
        }

        // $q.notify({
        //   message: 'Avvikelsen är skapad',
        //   icon: 'mdi-checkbox-marked-circle-outline',
        //   timeout: 3000,
        //   color: 'primary',
        //   textColor: 'white',
        //   position: 'center',
        //   actions: [{ label: 'Ok', color: 'white' }],
        // })

        formModal?.closeModal()
      }

      if (formModal?.state.mode === 'update') {
        await updateDeviationDelay(model.value)
        formModal?.closeModal()
      }
    }

    const saveAndCreateNew = async () => {
      await onSubmit().then(() => {
        window.setTimeout(() => {
          if (formModal) {
            formModal.openModal({
              mode: 'create',
              data: Object.assign({}, model.value),
            })
          }
        }, 100)
      })
    }

    const loading = computed(() => {
      return (
        loadingStateDeviationDelay.value.create ||
        loadingStateDeviationDelay.value.update
      )
    })

    return {
      loading,
      model,
      formModal,
      formRef,
      onSubmit,
      saveAndCreateNew,
    }
  },
})

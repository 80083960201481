<template>
  <div class="row">
    <div class="col q-pa-md">
      <q-radio v-model="model" val="tågvärd" label="Tågvärd" />
      <q-radio
        v-model="model"
        val="serveringspersonal"
        label="Serveringspersonal"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'DeviationCrewTypeInput',

  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const model = ref(props.modelValue)

    watch(model, (value) => {
      emit('update:modelValue', value)
    })

    return {
      model,
    }
  },
})
</script>


import { computed, defineComponent, PropType } from 'vue'
import DeviationTable from '@/components/deviation/DeviationTable.vue'
import QTable from 'quasar/src/components/table/QTable.js';
import format from 'date-fns/format'
import { buildExcelSheetFromTable } from '@/common/buildExcelSheetFromTable'
import { DeviationDisruption } from '@/types/deviation-disruption'

export default defineComponent({
  name: 'DeviationDisruptionTable',

  components: {
    DeviationTable,
  },

  props: {
    addColumns: {
      type: Array as PropType<QTable['columns']>,
      default: () => [],
    },
    loading: Boolean,
    height: {
      type: String,
      default: () => 'calc(100vh - 140px - 166px)',
    },
    deviationDisruptions: {
      type: Array as PropType<DeviationDisruption[]>,
      required: true,
    },
  },

  setup(props) {
    const columns: QTable['columns'] = [
      ...(props?.addColumns || []),
      {
        align: 'left',
        name: 'disruptionPlace',
        required: true,
        label: 'Störningsplats',
        field: 'disruptionPlace',
        sortable: true,
        style: 'width: 0px; min-width: 0px; max-width: 0px',
      },
      {
        align: 'left',
        name: 'departureDate',
        required: true,
        label: 'Avgångsdatum',
        field: 'departureDate',
        format: (v: string) => format(new Date(v), 'yyyy-MM-dd'),
        sortable: true,
        style: 'width: 0px; min-width: 0px; max-width: 0px',
      },
      {
        align: 'left',
        name: 'departure',
        format: (v: string) => format(new Date(v), 'HH:mm'),
        required: true,
        label: 'Avg.',
        field: 'departure',
        sortable: true,
        style: 'width: 0px; min-width: 0px; max-width: 0px',
      },
      {
        align: 'left',
        name: 'arrival',
        format: (v: string) => format(new Date(v), 'HH:mm'),
        required: true,
        label: 'Ank.',
        field: 'arrival',
        sortable: true,
        style: 'width: 0px; min-width: 0px; max-width: 0px',
      },
      {
        align: 'left',
        name: 'description',
        required: true,
        label: 'Kommentar',
        field: 'description',
        sortable: true,
      },
      {
        align: 'left',
        name: 'activity',
        required: true,
        label: 'Aktivitet',
        field: 'activity',
        sortable: true,
        style: 'width: 0px; min-width: 0px; max-width: 0px',
      },
    ]

    const exportData = computed(() => {
      return buildExcelSheetFromTable({
        name: 'Störningshantering',
        table: props.deviationDisruptions,
        columns: columns,
      })
    })

    const loadingState = computed(() => {
      return props.loading
    })

    return { exportData, columns, loadingState }
  },
})

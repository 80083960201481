<template>
  <q-card style="max-width: 100%; width: 948px">
    <q-form
      ref="formRef"
      @submit.prevent="saveAndCreateNew"
      autocomplete="off"
      greedy
    >
      <q-card-section class="row items-center text-h6">
        {{
          ['create', 'create-proposal'].includes(formModal.state.mode)
            ? 'Skapa'
            : 'Redigera'
        }}
        fordonsavvikelse</q-card-section
      >

      <q-separator />

      <q-card-section class="q-pa-none">
        <DeviationEventInput
          :event-uuid="model.eventUuid"
          v-if="showEventInput"
          v-model="model"
        />
        <div class="row">
          <div class="col-7">
            <DeviationSearchTrainInput
              deviation-type="deviationVehicle"
              v-model="model"
              :mode="formModal.state.mode"
              :skip-search-train-composition="
                formModal.state.mode === 'create-proposal'
              "
            />
            <DeviationVehicleTypeInput
              :mode="formModal.state.mode"
              v-model="model"
              v-if="projectDependentInputFields.vehicleType"
            />
          </div>
          <div class="col-5">
            <DeviationVehicleChangeInput
              v-if="projectDependentInputFields.vehicleChange"
              v-model="model.vehicleChange"
            />
            <DeviationDescriptionInput v-model="model.description" />
          </div>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn
          flat
          label="Avbryt"
          @click="formModal.closeModal"
          :disable="loading"
        />
        <q-btn
          label="Spara & skapa ny"
          outline
          color="primary"
          type="submit"
          :loading="loading"
        />
        <q-btn
          label="Spara & stäng"
          color="primary"
          :loading="loading"
          @click="onSubmit"
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { QForm } from 'quasar'
import { computed, defineComponent, inject, onBeforeMount, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { DeviationVehicle } from '@/types/deviation-vehicle'
import { useDeviationVehicle } from '@/composable/useDeviationVehicle'
import { useProfile } from '@/composable/useProfile'
import DeviationSearchTrainInput from '@/components/deviation/DeviationTrainSearchInput.vue'
import DeviationVehicleChangeInput from '@/components/deviation/DeviationVehicleChangeInput.vue'
import DeviationDescriptionInput from '@/components/deviation/DeviationDescriptionInput.vue'
import DeviationVehicleTypeInput from '@/components/deviation/DeviationVehicleTypeInput.vue'
import DeviationEventInput from '@/components/deviation/DeviationEventInput.vue'
import { useDeviationVehicleProposal } from '@/composable/useDeviationVehicleProposal'
import { useEvent } from '@/composable/useEvent'
import { format } from 'date-fns'

export default defineComponent({
  name: 'DeviationVehicleFormModal',

  components: {
    DeviationSearchTrainInput,
    DeviationVehicleChangeInput,
    DeviationDescriptionInput,
    DeviationVehicleTypeInput,
    DeviationEventInput,
  },

  props: {
    eventUuid: {
      type: String,
    },
    showEventInput: Boolean,
  },

  setup(props) {
    const {
      add: addDeviationVehicle,
      update: updateDeviationVehicle,
      loading: loadingStateDeviationVehicle,
    } = useDeviationVehicle()
    const { fetchAll: fetchAllProposal } = useDeviationVehicleProposal()
    const { projectDependentInputFields } = useProfile()
    const formModal = inject<UseModal<Partial<DeviationVehicle>>>(
      'deviation-vehicle-form-modal'
    )
    const formRef = ref<QForm | null>(null)
    // const $q = useQuasar()

    const { data: event } = useEvent()

    const model = ref<Partial<DeviationVehicle>>({
      uuid: uuidv4(),
      departureDate: new Date(),
      departure: new Date(),
      arrival: new Date(),
      advertised: null,
      from: '',
      to: '',
      vehicleChange: 0,
      description: '',
      eventUuid: props.eventUuid || null,
      newVehicleType1: null,
      newVehicleType2: null,
      newVehicleType3: null,
      plannedVehicleType1: null,
      plannedVehicleType2: null,
      plannedVehicleType3: null,
      eventName: null,
      eventId: null,
      eventIsOpen: null,
      eventDate: null,
    })

    // If update modal set event to model
    onBeforeMount(() => {
      if (formModal?.state.data && formModal?.state.mode === 'update') {
        model.value.eventName = formModal.state.data.eventName
        model.value.uuid = formModal.state.data.uuid
        model.value.departureDate = formModal.state.data.departureDate
        model.value.departure = formModal.state.data.departure
        model.value.arrival = formModal.state.data.arrival
        model.value.advertised = formModal.state.data.advertised
        model.value.from = formModal.state.data.from
        model.value.to = formModal.state.data.to
        model.value.vehicleChange = formModal.state.data.vehicleChange
        model.value.description = formModal.state.data.description
        model.value.eventUuid = formModal.state.data.eventUuid
        model.value.newVehicleType1 = formModal.state.data.newVehicleType1
        model.value.newVehicleType2 = formModal.state.data.newVehicleType2
        model.value.newVehicleType3 = formModal.state.data.newVehicleType3
        model.value.plannedVehicleType1 =
          formModal.state.data.plannedVehicleType1
        model.value.plannedVehicleType2 =
          formModal.state.data.plannedVehicleType2
        model.value.plannedVehicleType3 =
          formModal.state.data.plannedVehicleType3

        model.value.eventId = formModal.state.data.eventId
        model.value.eventIsOpen = formModal.state.data.eventIsOpen
        model.value.eventDate = formModal.state.data.eventDate
      }

      if (
        formModal?.state.data &&
        formModal?.state.mode === 'create-proposal'
      ) {
        model.value.advertised = formModal.state.data.advertised
        model.value.departureDate = formModal.state.data.departureDate
        model.value.from = formModal.state.data.from
        model.value.to = formModal.state.data.to
        model.value.plannedVehicleType1 =
          formModal.state.data.plannedVehicleType1
        model.value.newVehicleType1 = formModal.state.data.newVehicleType1
        model.value.to = formModal.state.data.to
        model.value.plannedVehicleType2 =
          formModal.state.data.plannedVehicleType2
        model.value.newVehicleType2 = formModal.state.data.newVehicleType2
        model.value.plannedVehicleType3 =
          formModal.state.data.plannedVehicleType3
        model.value.newVehicleType3 = formModal.state.data.newVehicleType3
        model.value.vehicleChange = formModal.state.data.vehicleChange
      }

      if (
        formModal?.state.data &&
        formModal?.state.mode === 'create' &&
        formModal.state.data.departureDate
      ) {
        model.value.departureDate = formModal.state.data.departureDate
      }
    })

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid) return Promise.reject()

      const modelCopy = Object.assign({}, model.value)

      if (!projectDependentInputFields.value.vehicleChange) {
        delete modelCopy.vehicleChange
      }

      if (!projectDependentInputFields.value.vehicleType) {
        delete modelCopy.newVehicleType1
        delete modelCopy.newVehicleType2
        delete modelCopy.newVehicleType3

        delete modelCopy.plannedVehicleType1
        delete modelCopy.plannedVehicleType2
        delete modelCopy.plannedVehicleType3
      }

      if (['create', 'create-proposal'].includes(formModal?.state.mode || '')) {
        await addDeviationVehicle(modelCopy)
        if (formModal?.state.mode === 'create-proposal') {
          if (event.value) {
            const period = {
              from: format(new Date(event.value.start), 'yyyy-MM-dd'),
              to: format(new Date(event.value.start), 'yyyy-MM-dd'),
            }
            await fetchAllProposal(period)
          }

          formModal?.closeModal()
          return
        }

        // $q.notify({
        //   message: 'Avvikelsen är skapad',
        //   icon: 'mdi-checkbox-marked-circle-outline',
        //   timeout: 3000,
        //   color: 'primary',
        //   textColor: 'white',
        //   position: 'center',
        //   actions: [{ label: 'Ok', color: 'white' }],
        // })

        formModal?.closeModal()
      }

      if (formModal?.state.mode === 'update') {
        await updateDeviationVehicle(modelCopy)
      }

      formModal?.closeModal()
    }

    const saveAndCreateNew = async () => {
      await onSubmit().then(() => {
        window.setTimeout(() => {
          if (formModal) {
            formModal.openModal({
              mode: 'create',
              data: Object.assign({}, model.value),
            })
          }
        }, 100)
      })
    }

    const loading = computed(() => {
      return (
        loadingStateDeviationVehicle.value.create ||
        loadingStateDeviationVehicle.value.update
      )
    })

    return {
      loading,
      model,
      formModal,
      formRef,
      onSubmit,
      projectDependentInputFields,
      saveAndCreateNew,
    }
  },
})
</script>


import { UseModal } from '@/composable/useModal'
import QForm from 'quasar/src/components/form/QForm.js';
import { computed, defineComponent, inject, onBeforeMount, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import DeviationDescriptionInput from '@/components/deviation/DeviationDescriptionInput.vue'
import DeviationEventInput from '@/components/deviation/DeviationEventInput.vue'
import AppFieldDate from '@/components/AppFieldDate.vue'
import {
  validDateRule,
  required as requiredRule,
  requiredNotNull,
} from '@/common/formValidationRules'
import { addHours, format } from 'date-fns'
import { DeviationDisruption } from '@/types/deviation-disruption'
import { useDeviationDisruption } from '@/composable/useDeviationDisruption'
import AppFieldSelect from '@/components/AppFieldSelect.vue'
import { useDeviationDisruptionReason } from '@/composable/useDeviationDisruptionReason'

export default defineComponent({
  name: 'DeviationDisruptionFormModal',

  components: {
    DeviationDescriptionInput,
    DeviationEventInput,
    AppFieldDate,
    AppFieldSelect,
  },

  props: {
    eventUuid: {
      type: String,
    },
    showEventInput: Boolean,
  },

  setup(props) {
    const {
      add: addDeviationDisruption,
      update: updateDeviationDisruption,
      loading: loadingStateDeviationDisruption,
    } = useDeviationDisruption()

    const {
      data: deviationDisruptionReasons,
      fetchAll: fetchAllDisruptionReasons,
      loading: loadingStateDisruptionReasons,
    } = useDeviationDisruptionReason()

    fetchAllDisruptionReasons()

    const formModal = inject<UseModal<DeviationDisruption>>(
      'deviation-disruption-form-modal'
    )
    const formRef = ref<QForm | null>(null)
    // const $q = useQuasar()

    const model = ref<Partial<DeviationDisruption>>({
      uuid: uuidv4(),
      disruptionPlace: '',
      departureDate: format(new Date(), 'yyyy-MM-dd'),
      departure: format(new Date(), 'HH:mm'),
      arrival: format(addHours(new Date(), 1), 'HH:mm'),
      description: '',
      activity: '',
      eventUuid: props.eventUuid,
      eventName: null,
      deviationDisruptionActivityUuid: '',
      eventId: null,
      eventIsOpen: null,
      eventDate: null,
    })

    // If update modal set event to model
    onBeforeMount(() => {
      if (formModal?.state.data && formModal?.state.mode === 'update') {
        model.value.eventName = formModal.state.data.eventName
        model.value.uuid = formModal.state.data.uuid
        model.value.departureDate = format(
          new Date(formModal.state.data.departureDate),
          'yyyy-MM-dd'
        )
        model.value.arrival = format(
          new Date(formModal.state.data.arrival),
          'HH:mm'
        )
        model.value.departure = format(
          new Date(formModal.state.data.departure),
          'HH:mm'
        )
        model.value.description = formModal.state.data.description
        model.value.eventUuid = formModal.state.data.eventUuid
        model.value.disruptionPlace = formModal.state.data.disruptionPlace
        model.value.activity = formModal.state.data.activity

        model.value.eventId = formModal.state.data.eventId
        model.value.eventIsOpen = formModal.state.data.eventIsOpen
        model.value.eventDate = formModal.state.data.eventDate
      }
    })

    const arrivalDate = ref(format(new Date(), 'yyyy-MM-dd'))

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid || !deviationDisruptionReasons.value.length)
        return Promise.reject()

      const reason = deviationDisruptionReasons.value.find(
        (x) => x.name === model.value.activity
      )

      if (!reason) return Promise.reject()

      model.value.deviationDisruptionActivityUuid = reason.uuid

      const payload = {
        ...model.value,
        departure: new Date(
          `${model.value.departureDate} ${model.value.departure}`
        ),
        arrival: new Date(`${arrivalDate.value} ${model.value.arrival}`),
      }

      if (formModal?.state.mode === 'create') {
        await addDeviationDisruption(payload)
        formModal?.closeModal()
        // $q.notify({
        //   message: 'Avvikelsen är skapad',
        //   icon: 'mdi-checkbox-marked-circle-outline',
        //   timeout: 3000,
        //   color: 'primary',
        //   textColor: 'white',
        //   position: 'center',
        //   actions: [{ label: 'Ok', color: 'white' }],
        // })
      }

      if (formModal?.state.mode === 'update') {
        await updateDeviationDisruption(payload)
        formModal?.closeModal()
      }
    }

    const saveAndCreateNew = async () => {
      await onSubmit().then(() => {
        window.setTimeout(() => {
          if (formModal) {
            formModal.openModal({ mode: 'create' })
          }
        }, 100)
      })
    }

    const loading = computed(() => {
      return (
        loadingStateDeviationDisruption.value.create ||
        loadingStateDeviationDisruption.value.update
      )
    })

    const deviationDisruptionReasonsParsed = computed(() => {
      return deviationDisruptionReasons.value.map((x) => x.name)
    })

    return {
      loading,
      model,
      formModal,
      formRef,
      onSubmit,
      requiredRule,
      validDateRule,
      requiredNotNull,
      deviationDisruptionReasons,
      loadingStateDisruptionReasons,
      deviationDisruptionReasonsParsed,
      arrivalDate,
      saveAndCreateNew,
    }
  },
})

<template>
  <DeviationTable
    deviation-type="deviationDelay"
    :export-data="exportData"
    :loading="loading"
    :rows="deviationDelays"
    :columns="columns"
    :height="height"
    deviation-proposal-type="delay"
  >
    <template v-slot:top-left-buttons>
      <slot name="top-left-buttons"></slot>
    </template>
    <template v-slot:top-right-buttons>
      <slot name="top-right-buttons"></slot>
    </template>
    <template v-slot:top-right-extra>
      <slot name="top-right-extra"></slot>
    </template>
  </DeviationTable>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import DeviationTable from '@/components/deviation/DeviationTable.vue'
import { QTable } from 'quasar'
import { DeviationDelay } from '@/types/deviation-delay'
import format from 'date-fns/format'
import { buildExcelSheetFromTable } from '@/common/buildExcelSheetFromTable'

export default defineComponent({
  name: 'DeviationVehicleTable',

  components: {
    DeviationTable,
  },

  props: {
    addColumns: {
      type: Array as PropType<QTable['columns']>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: () => 'calc(100vh - 140px - 166px)',
    },
    deviationDelays: {
      type: Array as PropType<DeviationDelay[]>,
      required: true,
    },
  },

  setup(props) {
    const columns: QTable['columns'] = [
      ...(props?.addColumns || []),
      {
        align: 'left',
        name: 'advertised',
        required: true,
        label: 'Tåguppdrag',
        field: 'advertised',
        sortable: true,
        style: 'width: 0px; min-width: 0px; max-width: 0px',
      },
      {
        align: 'left',
        name: 'departureDate',
        required: true,
        label: 'Avgångsdatum',
        field: 'departureDate',
        format: (v: string) => format(new Date(v), 'yyyy-MM-dd'),
        sortable: true,
        style: 'width: 0px; min-width: 0px; max-width: 0px',
      },
      {
        align: 'left',
        name: 'delayType',
        required: true,
        label: 'Förseningstyp',
        field: 'delayType',
        sortable: true,
        style: 'width: 0px; min-width: 0px; max-width: 0px',
      },
      {
        align: 'left',
        name: 'delayLocation',
        required: true,
        label: 'Plats',
        field: 'delayLocation',
        sortable: true,
        style: 'width: 0px; min-width: 0px; max-width: 0px',
      },
      {
        align: 'left',
        name: 'delayPlannedTime',
        format: (v: string) => format(new Date(v), 'HH:mm'),
        required: true,
        label: 'Annonserad tid',
        field: (x) =>
          x.eventUuid ? x.delayAdvertisedTime : x.delayPlannedTime,
        sortable: true,
        style: 'width: 0px; min-width: 0px; max-width: 0px',
      },
      {
        align: 'left',
        name: 'delayMinutes',
        required: true,
        label: 'Försening (min)',
        field: 'delayMinutes',
        sortable: true,
        style: 'width: 0px; min-width: 0px; max-width: 0px',
      },
      {
        align: 'left',
        name: 'delayReason',
        required: true,
        label: 'TRV orsakskod',
        field: 'delayReason',
        sortable: true,
        style: 'width: 0px; min-width: 0px; max-width: 0px',
      },
      {
        align: 'left',
        name: 'delayReasonDescription',
        required: true,
        label: 'TRV händelse Id',
        field: 'delayReasonDescription',
        sortable: true,
      },
      {
        align: 'left',
        name: 'bana',
        required: true,
        label: 'Bana',
        field: 'bana',
        sortable: true,
      },
      {
        align: 'left',
        name: 'description',
        required: true,
        label: 'Kommentar',
        field: 'description',
        sortable: true,
      },
    ]

    const exportData = computed(() => {
      return buildExcelSheetFromTable({
        name: 'Förseningar',
        table: (
          props.deviationDelays as (DeviationDelay & { missingTrv: boolean })[]
        ).map((x) => ({
          ...x,
          eventIsOpen: x.eventIsOpen ? 'Ja' : '',
          missingTrv: x.missingTrv ? 'Ja' : '',
        })),
        columns: columns,
      })
    })

    const loadingState = computed(() => {
      return props.loading
    })

    return { exportData, columns, loadingState }
  },
})
</script>

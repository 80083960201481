import { Ref, ref, ComputedRef, computed } from 'vue'
import { Vehicle } from '@/types/vehicle'
import { getVehicles } from '@/api/vehicle/getVehicles'
import { deleteVehicle } from '@/api/vehicle/deleteVehicle'
import { createVehicle } from '@/api/vehicle/createVehicle'
import { updateVehicle } from '@/api/vehicle/updateVehicle'
import { useLoading, Loading } from '@/composable/useLoading'

interface UseVehicle {
  data: Ref<Vehicle[]>
  fetchAll: () => Promise<Vehicle[]>
  remove: (uuid: string) => Promise<null>
  add: (body: Vehicle) => Promise<Vehicle>
  update: (body: Vehicle) => Promise<Vehicle>
  loading: ComputedRef<Loading>
}

const data = ref<Vehicle[]>([])
const { state } = useLoading()

export function useVehicle(initFetchAll?: boolean): UseVehicle {
  async function fetchAll() {
    state.getAll = true
    return new Promise<Vehicle[]>((resolve, reject) => {
      getVehicles()
        .then(({ data: vehicles }) => {
          data.value = vehicles
          resolve(vehicles)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteVehicle(uuid)
        .then(() => {
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: Vehicle) {
    state.create = true
    return new Promise<Vehicle>((resolve, reject) => {
      createVehicle(body)
        .then(({ data: vehicle }) => {
          data.value.push(vehicle)
          resolve(vehicle)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: Vehicle) {
    state.update = true
    return new Promise<Vehicle>((resolve, reject) => {
      updateVehicle(body)
        .then(({ data: vehicle }) => {
          data.value = data.value.map((item) => {
            if (item.uuid === vehicle.uuid) {
              return {
                ...item,
                ...vehicle,
              }
            }

            return item
          })
          resolve(vehicle)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  if (initFetchAll) {
    fetchAll()
  }

  return {
    data,
    fetchAll,
    remove,
    add,
    update,
    loading: computed(() => state),
  }
}

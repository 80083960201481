
import {
  requiredNotNumber,
  requiredNotNegative,
} from '@/common/formValidationRules'
import { computed, defineComponent, ref, watch } from 'vue'
import isNumber from 'lodash.isnumber'

export default defineComponent({
  name: 'DeviationCrewCountInput',

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const model = ref(props.modelValue)

    watch(
      model,
      (value) => {
        const parseModel = {
          ...props.modelValue,
          newStaffCount1: isNumber(value.newStaffCount1)
            ? value.newStaffCount1
            : null,
          newStaffCount2: isNumber(value.newStaffCount2)
            ? value.newStaffCount2
            : null,
          newStaffCount3: isNumber(value.newStaffCount3)
            ? value.newStaffCount3
            : null,
          plannedStaffCount1: isNumber(value.plannedStaffCount1)
            ? value.plannedStaffCount1
            : null,
          plannedStaffCount2: isNumber(value.plannedStaffCount2)
            ? value.plannedStaffCount2
            : null,
          plannedStaffCount3: isNumber(value.plannedStaffCount3)
            ? value.plannedStaffCount3
            : null,
        }

        if (!parseModel.plannedStaffCount2) {
          parseModel.newStaffCount2 = null
          model.value.newStaffCount2 = null
        }
        if (!parseModel.plannedStaffCount3) {
          parseModel.newStaffCount3 = null
          model.value.newStaffCount3 = null
        }
        emit('update:modelValue', parseModel)
      },
      { deep: true }
    )

    const crewStatus = computed(() => {
      if (
        model.value.newStaffCount1 === 0 ||
        model.value.newStaffCount2 === 0 ||
        model.value.newStaffCount3 === 0
      ) {
        return 'Indragen bemanning'
      }
      return 'Reducerad bemanning'
    })

    return {
      crewStatus,
      isNumber,
      model,
      requiredNotNumber,
      requiredNotNegative,
    }
  },
})


import { computed, defineComponent, PropType } from 'vue'
import DeviationTable from '@/components/deviation/DeviationTable.vue'
import QTable from 'quasar/src/components/table/QTable.js';
import format from 'date-fns/format'
import { DeviationCrew } from '@/types/deviation-crew'
import { useProfile } from '@/composable/useProfile'
import { buildExcelSheetFromTable } from '@/common/buildExcelSheetFromTable'

export default defineComponent({
  name: 'DeviationCrewTable',

  components: {
    DeviationTable,
  },

  props: {
    addColumns: {
      type: Array as PropType<QTable['columns']>,
      default: () => [],
    },
    loading: Boolean,
    height: {
      type: String,
      default: () => 'calc(100vh - 140px - 166px)',
    },
    deviationCrews: {
      type: Array as PropType<DeviationCrew[]>,
      required: true,
    },
  },

  setup(props) {
    const { projectDependentInputFields } = useProfile()

    const columns = computed<QTable['columns']>(() => {
      let defaultColumns: QTable['columns'] = [
        ...(props?.addColumns || []),
        {
          align: 'left',
          name: 'advertised',
          required: true,
          label: 'Tåguppdrag',
          field: 'advertised',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'departureDate',
          required: true,
          label: 'Avgångsdatum',
          field: 'departureDate',
          format: (v: string) => format(new Date(v), 'yyyy-MM-dd'),
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'from',
          required: true,
          label: 'Från plats',
          field: 'from',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'to',
          required: true,
          label: 'Till plats',
          field: 'to',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'departure',
          format: (v: string) => format(new Date(v), 'HH:mm'),
          required: true,
          label: 'Avg.',
          field: 'departure',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'arrival',
          format: (v: string) => format(new Date(v), 'HH:mm'),
          required: true,
          label: 'Ank.',
          field: 'arrival',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'vacancyHandedOver',
          required: true,
          label: 'PPL',
          format: (v: boolean) => (v ? 'Ja' : 'Nej'),
          field: 'vacancyHandedOver',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
      ]
      if (projectDependentInputFields.value.crewType) {
        defaultColumns.push({
          align: 'left',
          name: 'type',
          required: true,
          label: 'Typ',
          field: 'type',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        })
      }

      if (projectDependentInputFields.value.crewChange) {
        defaultColumns.push({
          align: 'left',
          name: 'crewChange',
          required: true,
          label: 'Förändring bemanning',
          field: 'crewChange',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        })
      }

      if (projectDependentInputFields.value.crewCount) {
        defaultColumns = [
          ...defaultColumns,
          {
            align: 'left',
            name: 'plannedStaffCount1',
            required: true,
            label: 'Ord. M1',
            field: 'plannedStaffCount1',
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
          {
            align: 'left',
            name: 'plannedStaffCount2',
            required: true,
            label: 'Ord. M2',
            field: 'plannedStaffCount2',
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
          {
            align: 'left',
            name: 'plannedStaffCount3',
            required: true,
            label: 'Ord. M3',
            field: 'plannedStaffCount3',
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
          {
            align: 'left',
            name: 'newStaffCount1',
            required: true,
            label: 'Ny M1',
            field: 'newStaffCount1',
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
          {
            align: 'left',
            name: 'newStaffCount2',
            required: true,
            label: 'Ny M2',
            field: 'newStaffCount2',
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
          {
            align: 'left',
            name: 'newStaffCount3',
            required: true,
            label: 'Ny M3',
            field: 'newStaffCount3',
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
        ]
      }

      return [
        ...defaultColumns,
        {
          align: 'left',
          name: 'description',
          required: true,
          label: 'Kommentar',
          field: 'description',
          sortable: true,
        },
      ]
    })

    const exportData = computed(() => {
      return buildExcelSheetFromTable({
        name: 'Bemanning',
        table: props.deviationCrews,
        columns: columns.value,
      })
    })

    const loadingState = computed(() => {
      return props.loading
    })

    return { exportData, columns, loadingState }
  },
})

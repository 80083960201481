<template>
  <DeviationTable
    deviation-type="deviationVehicle"
    :export-data="exportData"
    :loading="loadingState"
    :rows="rows"
    :columns="columns"
    :height="height"
    deviation-proposal-type="vehicle"
  >
    <template v-slot:top-left-buttons>
      <slot name="top-left-buttons"></slot>
    </template>
    <template v-slot:top-right-buttons>
      <slot name="top-right-buttons"></slot>
    </template>
    <template v-slot:top-right-extra>
      <slot name="top-right-extra"></slot>
    </template>
  </DeviationTable>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import DeviationTable from '@/components/deviation/DeviationTable.vue'
import { QTable } from 'quasar'
import { DeviationVehicle } from '@/types/deviation-vehicle'
import format from 'date-fns/format'
import { useProfile } from '@/composable/useProfile'
import { useVehicleType } from '@/composable/useVehicleType'
import { buildExcelSheetFromTable } from '@/common/buildExcelSheetFromTable'

export default defineComponent({
  name: 'DeviationVehicleTable',

  components: {
    DeviationTable,
  },

  props: {
    addColumns: {
      type: Array as PropType<QTable['columns']>,
      default: () => [],
    },
    loading: Boolean,
    height: {
      type: String,
      default: () => 'calc(100vh - 140px - 166px)',
    },
    deviationVehicles: {
      type: Array as PropType<DeviationVehicle[]>,
      required: true,
    },
  },

  setup(props) {
    const { projectDependentInputFields } = useProfile()
    const {
      fetchAll,
      loading: loadingVehiceType,
      data: vehicleTypes,
    } = useVehicleType()

    fetchAll()

    function getVehicleTypeName(vehicleTypeUuid: string) {
      return (
        vehicleTypes.value.find(
          (vehicleType) => vehicleType.uuid === vehicleTypeUuid
        )?.name ||
        vehicleTypeUuid ||
        '-'
      )
    }

    const columns = computed<QTable['columns']>(() => {
      let defaultColumns = [
        ...(props?.addColumns || []),
        {
          align: 'left',
          name: 'advertised',
          required: true,
          label: 'Tåguppdrag',
          field: 'advertised',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'departureDate',
          required: true,
          label: 'Avgångsdatum',
          field: 'departureDate',
          format: (v: string) => format(new Date(v), 'yyyy-MM-dd'),
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'from',
          required: true,
          label: 'Från plats',
          field: 'from',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'to',
          required: true,
          label: 'Till plats',
          field: 'to',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'departure',
          format: (v: string) => format(new Date(v), 'HH:mm'),
          required: true,
          label: 'Avg.',
          field: 'departure',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
        {
          align: 'left',
          name: 'arrival',
          format: (v: string) => format(new Date(v), 'HH:mm'),
          required: true,
          label: 'Ank.',
          field: 'arrival',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        },
      ]
      if (projectDependentInputFields.value.vehicleChange) {
        defaultColumns.push({
          align: 'left',
          name: 'vehicleChange',
          required: true,
          label: 'Förändring antal fordon',
          field: 'vehicleChange',
          sortable: true,
          style: 'width: 0px; min-width: 0px; max-width: 0px',
        })
      }

      if (projectDependentInputFields.value.vehicleType) {
        defaultColumns = [
          ...defaultColumns,
          {
            align: 'left',
            name: 'plannedVehicleType1',
            required: true,
            label: 'Ord. M1',
            field: 'plannedVehicleType1',
            format: getVehicleTypeName,
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
          {
            align: 'left',
            name: 'plannedVehicleType2',
            required: true,
            label: 'Ord. M2',
            format: getVehicleTypeName,
            field: 'plannedVehicleType2',
            style: 'width: 0px; min-width: 0px; max-width: 0px',
            sortable: true,
          },
          {
            align: 'left',
            name: 'plannedVehicleType3',
            required: true,
            label: 'Ord. M3',
            format: getVehicleTypeName,
            field: 'plannedVehicleType3',
            style: 'width: 0px; min-width: 0px; max-width: 0px',
            sortable: true,
          },
          {
            align: 'left',
            name: 'newVehicleType1',
            required: true,
            label: 'Ny M1',
            format: getVehicleTypeName,
            field: 'newVehicleType1',
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
          {
            align: 'left',
            name: 'newVehicleType2',
            required: true,
            label: 'Ny M2',
            format: getVehicleTypeName,
            field: 'newVehicleType2',
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
          {
            align: 'left',
            name: 'newVehicleType3',
            required: true,
            label: 'Ny M3',
            format: getVehicleTypeName,
            field: 'newVehicleType3',
            sortable: true,
            style: 'width: 0px; min-width: 0px; max-width: 0px',
          },
        ]
      }

      const columns = [
        ...defaultColumns,
        {
          align: 'left',
          name: 'description',
          required: true,
          label: 'Kommentar',
          field: 'description',
          sortable: true,
        },
      ]

      return columns as QTable['columns']
    })

    function findVehicleTypeUuid(name: string | undefined | null) {
      if (!name) return null
      return vehicleTypes.value.find((x) => x.name === name)?.uuid || null
    }

    const rows = computed(() => {
      return props.deviationVehicles
        .map((deviationVehicle) => {
          if (deviationVehicle.uuid) {
            return deviationVehicle
          }

          return {
            ...deviationVehicle,
            plannedVehicleType1: findVehicleTypeUuid(
              deviationVehicle.plannedVehicleType1
            ),
            plannedVehicleType2: findVehicleTypeUuid(
              deviationVehicle.plannedVehicleType2
            ),
            plannedVehicleType3: findVehicleTypeUuid(
              deviationVehicle.plannedVehicleType3
            ),
            newVehicleType1: findVehicleTypeUuid(
              deviationVehicle.newVehicleType1
            ),
            newVehicleType2: findVehicleTypeUuid(
              deviationVehicle.newVehicleType2
            ),
            newVehicleType3: findVehicleTypeUuid(
              deviationVehicle.newVehicleType3
            ),
          }
        })
        .filter((x) => {
          return x.uuid ? true : x.plannedVehicleType1 && x.newVehicleType1
        })
    })

    const exportData = computed(() => {
      return buildExcelSheetFromTable({
        name: 'Fordonsavvikelser',
        table: rows.value,
        columns: columns.value,
      })
    })

    const loadingState = computed(() => {
      return loadingVehiceType.value.getAll || props.loading
    })

    return { exportData, columns, loadingState, rows }
  },
})
</script>

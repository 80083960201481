<template>
  <div class="row">
    <div class="col q-pa-md">
      <AppFieldSelect
        label="Förändring antal fordon"
        v-model="model"
        emit-value
        map-options
        option-value="value"
        option-label="label"
        :options="vehicleChangeOptions"
        :rules="[requiredNotNull]"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { requiredNotNull } from '@/common/formValidationRules'
import { defineComponent, ref, watch } from 'vue'
import AppFieldSelect from '@/components/AppFieldSelect.vue'

export default defineComponent({
  name: 'DeviationVehicleChangeInput',

  components: {
    AppFieldSelect,
  },

  props: {
    modelValue: {
      type: Number,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const vehicleChangeOptions = [
      {
        label: '+2',
        value: +2,
      },
      {
        label: '+1',
        value: +1,
      },
      {
        label: '0',
        value: 0,
      },
      {
        label: '-1',
        value: -1,
      },
      {
        label: '-2',
        value: -2,
      },
    ]

    const model = ref(props.modelValue)

    watch(model, (value) => {
      emit('update:modelValue', value)
    })

    return {
      model,
      requiredNotNull,
      vehicleChangeOptions,
    }
  },
})
</script>

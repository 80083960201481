<template>
  <div class="row">
    <div class="col q-pa-md text-secondary">
      <q-icon name="mdi-information-outline" />
      {{ crewStatus }}
    </div>
  </div>
  <div class="row q-pa-md">
    <div class="col-4">
      <q-input
        label="Ord. M1"
        outlined
        square
        type="number"
        :rules="[requiredNotNumber, requiredNotNegative]"
        v-model.number="model.plannedStaffCount1"
      />
    </div>
    <div class="col-4">
      <q-input
        label="Ord. M2"
        outlined
        square
        type="number"
        :disable="!model.plannedStaffCount1"
        :rules="
          isNumber(model.plannedStaffCount2)
            ? [requiredNotNumber, requiredNotNegative]
            : []
        "
        v-model.number="model.plannedStaffCount2"
      />
    </div>
    <div class="col-4">
      <q-input
        label="Ord. M3"
        outlined
        square
        type="number"
        :disable="!model.plannedStaffCount2"
        :rules="
          isNumber(model.plannedStaffCount3)
            ? [requiredNotNumber, requiredNotNegative]
            : []
        "
        v-model.number="model.plannedStaffCount3"
      />
    </div>
  </div>
  <div class="row q-pa-md">
    <div class="col-4">
      <q-input
        label="Ny M1"
        outlined
        square
        type="number"
        :disable="!isNumber(model.plannedStaffCount1)"
        v-model.number="model.newStaffCount1"
        :rules="[requiredNotNumber, requiredNotNegative]"
      />
    </div>
    <div class="col-4">
      <q-input
        label="Ny M2"
        outlined
        square
        type="number"
        :disable="!isNumber(model.plannedStaffCount2)"
        v-model.number="model.newStaffCount2"
        :rules="[requiredNotNumber, requiredNotNegative]"
      />
    </div>
    <div class="col-4">
      <q-input
        label="Ny M3"
        outlined
        square
        type="number"
        :disable="!isNumber(model.plannedStaffCount3)"
        v-model.number="model.newStaffCount3"
        :rules="[requiredNotNumber, requiredNotNegative]"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  requiredNotNumber,
  requiredNotNegative,
} from '@/common/formValidationRules'
import { computed, defineComponent, ref, watch } from 'vue'
import isNumber from 'lodash.isnumber'

export default defineComponent({
  name: 'DeviationCrewCountInput',

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const model = ref(props.modelValue)

    watch(
      model,
      (value) => {
        const parseModel = {
          ...props.modelValue,
          newStaffCount1: isNumber(value.newStaffCount1)
            ? value.newStaffCount1
            : null,
          newStaffCount2: isNumber(value.newStaffCount2)
            ? value.newStaffCount2
            : null,
          newStaffCount3: isNumber(value.newStaffCount3)
            ? value.newStaffCount3
            : null,
          plannedStaffCount1: isNumber(value.plannedStaffCount1)
            ? value.plannedStaffCount1
            : null,
          plannedStaffCount2: isNumber(value.plannedStaffCount2)
            ? value.plannedStaffCount2
            : null,
          plannedStaffCount3: isNumber(value.plannedStaffCount3)
            ? value.plannedStaffCount3
            : null,
        }

        if (!parseModel.plannedStaffCount2) {
          parseModel.newStaffCount2 = null
          model.value.newStaffCount2 = null
        }
        if (!parseModel.plannedStaffCount3) {
          parseModel.newStaffCount3 = null
          model.value.newStaffCount3 = null
        }
        emit('update:modelValue', parseModel)
      },
      { deep: true }
    )

    const crewStatus = computed(() => {
      if (
        model.value.newStaffCount1 === 0 ||
        model.value.newStaffCount2 === 0 ||
        model.value.newStaffCount3 === 0
      ) {
        return 'Indragen bemanning'
      }
      return 'Reducerad bemanning'
    })

    return {
      crewStatus,
      isNumber,
      model,
      requiredNotNumber,
      requiredNotNegative,
    }
  },
})
</script>

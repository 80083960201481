<template>
  <q-card style="max-width: 100%; width: 948px">
    <q-form
      ref="formRef"
      @submit.prevent="saveAndCreateNew"
      autocomplete="off"
      greedy
    >
      <q-card-section class="row items-center text-h6">
        {{ formModal.state.mode === 'create' ? 'Skapa' : 'Redigera' }}
        bemanning</q-card-section
      >

      <q-separator />

      <q-card-section class="q-pa-none">
        <DeviationEventInput
          :event-uuid="model.eventUuid"
          v-if="showEventInput"
          v-model="model"
        />
        <div class="row">
          <div class="col-7">
            <DeviationSearchTrainInput
              deviation-type="deviationVehicle"
              v-model="model"
              :mode="formModal.state.mode"
            />
            <DeviationCrewCountInput
              v-model="model"
              v-if="projectDependentInputFields.crewCount"
            />
          </div>
          <div class="col-5">
            <div class="row" style="padding-bottom: 36px">
              <div class="col q-pa-md">
                <q-checkbox
                  v-model="model.vacancyHandedOver"
                  label="Vakans överlämnad"
                />
              </div>
            </div>
            <DeviationCrewTypeInput
              v-if="projectDependentInputFields.crewType"
              v-model="model.type"
            />
            <DeviationCrewChangeInput
              v-if="projectDependentInputFields.crewChange"
              v-model="model.crewChange"
            />
            <DeviationDescriptionInput
              label="Övrig information"
              v-model="model.description"
            />
          </div>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn
          flat
          label="Avbryt"
          @click="formModal.closeModal"
          type="button"
          :disable="loading"
        />
        <q-btn
          label="Spara & skapa ny"
          outline
          type="submit"
          color="primary"
          :loading="loading"
        />
        <q-btn
          label="Spara & stäng"
          color="primary"
          :loading="loading"
          @click="onSubmit"
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { QForm } from 'quasar'
import { computed, defineComponent, inject, onBeforeMount, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useDeviationCrew } from '@/composable/useDeviationCrew'
import { useProfile } from '@/composable/useProfile'
import DeviationSearchTrainInput from '@/components/deviation/DeviationTrainSearchInput.vue'
import DeviationDescriptionInput from '@/components/deviation/DeviationDescriptionInput.vue'
import DeviationCrewTypeInput from '@/components/deviation/DeviationCrewTypeInput.vue'
import DeviationCrewChangeInput from '@/components/deviation/DeviationCrewChangeInput.vue'
import DeviationCrewCountInput from '@/components/deviation/DeviationCrewCountInput.vue'
import { DeviationCrew } from '@/types/deviation-crew'
import DeviationEventInput from '@/components/deviation/DeviationEventInput.vue'

export default defineComponent({
  name: 'DeviationCrewFormModal',

  components: {
    DeviationSearchTrainInput,
    DeviationDescriptionInput,
    DeviationCrewTypeInput,
    DeviationCrewChangeInput,
    DeviationCrewCountInput,
    DeviationEventInput,
  },

  props: {
    eventUuid: {
      type: String,
    },
    showEventInput: Boolean,
  },

  setup(props) {
    const {
      add: addDeviationCrew,
      update: updateDeviationCrew,
      loading: loadingStateDeviationCrew,
    } = useDeviationCrew()
    const { projectDependentInputFields } = useProfile()
    const formModal = inject<UseModal<Partial<DeviationCrew>>>(
      'deviation-crew-form-modal'
    )
    const formRef = ref<QForm | null>(null)
    // const $q = useQuasar()

    const model = ref<Partial<DeviationCrew>>({
      uuid: uuidv4(),
      departureDate: new Date(),
      departure: new Date(),
      arrival: new Date(),
      advertised: null,
      from: '',
      to: '',
      description: '',
      eventUuid: props.eventUuid,
      type: 'tågvärd',
      crewChange: 0,
      plannedStaffCount1: null,
      plannedStaffCount2: null,
      plannedStaffCount3: null,
      newStaffCount1: null,
      newStaffCount2: null,
      newStaffCount3: null,
      vacancyHandedOver: false,
      eventName: null,
      eventId: null,
      eventIsOpen: null,
      eventDate: null,
    })

    // If update modal set event to model
    onBeforeMount(() => {
      if (formModal?.state.data && formModal?.state.mode === 'update') {
        model.value.eventName = formModal.state.data.eventName
        model.value.uuid = formModal.state.data.uuid
        model.value.departureDate = formModal.state.data.departureDate
        model.value.departure = formModal.state.data.departure
        model.value.arrival = formModal.state.data.arrival
        model.value.advertised = formModal.state.data.advertised
        model.value.from = formModal.state.data.from
        model.value.to = formModal.state.data.to
        model.value.description = formModal.state.data.description
        model.value.eventUuid = formModal.state.data.eventUuid
        model.value.type = formModal.state.data.type
        model.value.crewChange = formModal.state.data.crewChange
        model.value.vacancyHandedOver = formModal.state.data.vacancyHandedOver

        model.value.plannedStaffCount1 = formModal.state.data.plannedStaffCount1
        model.value.plannedStaffCount2 = formModal.state.data.plannedStaffCount2
        model.value.plannedStaffCount3 = formModal.state.data.plannedStaffCount3

        model.value.newStaffCount1 = formModal.state.data.newStaffCount1
        model.value.newStaffCount2 = formModal.state.data.newStaffCount2
        model.value.newStaffCount3 = formModal.state.data.newStaffCount3

        model.value.eventId = formModal.state.data.eventId
        model.value.eventIsOpen = formModal.state.data.eventIsOpen
        model.value.eventDate = formModal.state.data.eventDate
      }

      if (
        formModal?.state.data &&
        formModal?.state.mode === 'create' &&
        formModal.state.data.departureDate
      ) {
        model.value.departureDate = formModal.state.data.departureDate
      }
    })

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid) return Promise.reject()

      if (formModal?.state.mode === 'create') {
        await addDeviationCrew(model.value)
        formModal?.closeModal()
        // $q.notify({
        //   message: 'Avvikelsen är skapad',
        //   icon: 'mdi-checkbox-marked-circle-outline',
        //   timeout: 3000,
        //   color: 'primary',
        //   textColor: 'white',
        //   position: 'center',
        //   actions: [{ label: 'Ok', color: 'white' }],
        // })
      }

      if (formModal?.state.mode === 'update') {
        await updateDeviationCrew(model.value)
        formModal?.closeModal()
      }
    }

    const saveAndCreateNew = async () => {
      await onSubmit().then(() => {
        window.setTimeout(() => {
          if (formModal) {
            formModal.openModal({
              mode: 'create',
              data: Object.assign({}, model.value),
            })
          }
        }, 100)
      })
    }

    const loading = computed(() => {
      return (
        loadingStateDeviationCrew.value.create ||
        loadingStateDeviationCrew.value.update
      )
    })

    return {
      projectDependentInputFields,
      loading,
      model,
      formModal,
      formRef,
      onSubmit,
      saveAndCreateNew,
    }
  },
})
</script>

<template>
  <q-page>
    <q-dialog v-model="mpkModal.state.show" :persistent="false">
      <DeviationDelayMPKModal v-if="mpkModal.state.show" />
    </q-dialog>
    <q-dialog v-model="fordModal.state.show" :persistent="false">
      <EventFordModal v-if="fordModal.state.show" />
    </q-dialog>
    <q-dialog v-model="formModal.state.show" :persistent="false">
      <EventFormModal v-if="formModal.state.show" />
    </q-dialog>
    <q-dialog v-model="deleteModal.state.show" :persistent="false">
      <EventDeleteModal
        v-if="deleteModal.state.show"
        @confirm="onDeleteEvent"
      />
    </q-dialog>
    <q-dialog v-model="closeModal.state.show" :persistent="false">
      <EventCloseModal v-if="closeModal.state.show" />
    </q-dialog>

    <q-dialog v-model="openModal.state.show" :persistent="false">
      <EventOpenModal v-if="openModal.state.show" />
    </q-dialog>

    <q-dialog v-model="penaltyAssessmentModal.state.show" :persistent="false">
      <EventPenaltyAssessmentModal v-if="penaltyAssessmentModal.state.show" />
    </q-dialog>

    <q-dialog
      v-model="deviationVehicleFormModal.state.show"
      :persistent="false"
    >
      <DeviationVehicleFormModal
        v-if="deviationVehicleFormModal.state.show"
        :event-uuid="eventUuid"
      />
    </q-dialog>
    <q-dialog v-model="deviationDelayFormModal.state.show" :persistent="false">
      <DeviationDelayFormModal
        v-if="deviationDelayFormModal.state.show"
        :event-uuid="eventUuid"
      />
    </q-dialog>
    <q-dialog v-model="deviationCancelFormModal.state.show" :persistent="false">
      <DeviationCancelFormModal
        v-if="deviationCancelFormModal.state.show"
        :event-uuid="eventUuid"
      />
    </q-dialog>
    <q-dialog v-model="deviationCrewFormModal.state.show" :persistent="false">
      <DeviationCrewFormModal
        v-if="deviationCrewFormModal.state.show"
        :event-uuid="eventUuid"
      />
    </q-dialog>
    <q-dialog
      v-model="deviationDisruptionFormModal.state.show"
      :persistent="false"
    >
      <DeviationDisruptionFormModal
        v-if="deviationDisruptionFormModal.state.show"
        :event-uuid="eventUuid"
      />
    </q-dialog>
    <q-dialog
      v-model="deviationVehicleDeleteModal.state.show"
      :persistent="false"
    >
      <DeviationDeleteModal v-if="deviationVehicleDeleteModal.state.show" />
    </q-dialog>
    <transition
      enter-active-class="animated animate__fadeIn"
      leave-active-class="animated animate__fadeOut"
      mode="out-in"
      :duration="140"
    >
      <div v-if="!loadingStateEvents.get" style="min-height: inherit">
        <div class="row bg-grey-2">
          <div class="row q-px-md full-width q-py-sm">
            <div class="col-6">
              <div class="text-body1 row items-center">
                <div class="column q-mr-md">
                  <div class="flex flex-column">
                    <q-badge
                      rounded
                      :color="badgeColor"
                      style="height: 16px; width: 24px"
                      class="q-mr-sm"
                    />
                    <span class="text-body2 text-grey-7 text-weight-bold">{{
                      event.id
                    }}</span>
                  </div>
                  <div class="flex flex-column" v-if="event.linkedEventUuid">
                    <q-btn
                      size="sm"
                      flat
                      rounded
                      dense
                      @click="onClickLinkedEvent"
                    >
                      <q-icon name="mdi-attachment" />
                      <span class="q-px-sm">Länkad händelse</span>
                    </q-btn>
                  </div>
                </div>
                <q-separator vertical />
                <span class="q-ml-md text-h6 text-grey-7">{{
                  event.description
                }}</span>
              </div>
            </div>
            <div class="col-6 flex justify-end">
              <div class="flex column items-end">
                <EventRowActionsDropdown :event="event" skip-fetch-event />
              </div>
            </div>
          </div>

          <div class="row q-px-md q-py-sm text-grey-9 full-width">
            <div class="q-mr-xl">
              <div class="text-caption text-grey-7">Händelsedatum</div>
              <div class="text-body2">{{ formatDate(event.start) }}</div>
            </div>
            <div class="q-mr-xl">
              <div class="text-caption text-grey-7">TRV ID</div>

              <template v-if="event.eventTrvs.length">
                <q-btn
                  outline
                  dense
                  size="12px"
                  class="q-mb-xs q-mr-xs"
                  @click="onClickMpkChip(eventId.trvId)"
                  v-for="(eventId, $i) in event.eventTrvs"
                  :key="$i"
                >
                  <template v-if="!loadingTrvIds">
                    <q-icon
                      v-if="!trvValidIds[eventId.trvId]"
                      size="14px"
                      name="mdi-alert"
                      color="negative"
                      class="q-mr-sm"
                    />
                  </template>
                  <q-spinner v-else color="black" size="1em" :thickness="2" />
                  {{ eventId.trvId }}
                </q-btn>
              </template>
            </div>
            <div class="q-mr-xl">
              <div class="text-caption text-grey-7">Orsak 1</div>
              <div class="text-body2">{{ event.reason.reason1 }}</div>
            </div>
            <div class="q-mr-xl">
              <div class="text-caption text-grey-7">Orsak 2</div>
              <div class="text-body2">{{ event.reason.reason2 }}</div>
            </div>
            <div class="q-mr-xl">
              <div class="text-caption text-grey-7">Orsak 3</div>
              <div class="text-body2">{{ event.reason.reason3 }}</div>
            </div>

            <div class="q-mr-xl" v-if="event.causingVehicles.length">
              <div class="text-caption text-grey-7">Ford skadenr.</div>
              <div class="flex">
                <q-btn
                  @click="onClickFordChip(causingVehicle)"
                  :disable="!can('deviationManagement.vehicleDefect.list')"
                  dense
                  size="12px"
                  outline
                  class="q-mr-sm"
                  v-for="causingVehicle in event.causingVehicles"
                  :key="causingVehicle.id"
                >
                  {{ causingVehicle.vehicle.name }} | {{ causingVehicle.ford }}
                </q-btn>
              </div>
            </div>

            <div class="q-mr-xl" v-if="event.description">
              <div class="text-caption text-grey-7">
                Övergripande händelsebeskrivning
              </div>
              <div class="text-body2">{{ event.description }}</div>
            </div>

            <div class="q-mr-xl" v-if="event.information">
              <div class="text-caption text-grey-7">
                Övrig information/felbeskrivning
              </div>
              <div class="text-body2">{{ event.information }}</div>
            </div>

            <div class="q-mr-xl" v-if="event.reason.reason1 === 'Fordonsskada'">
              <div class="text-caption text-grey-7">Misstänkt HHF</div>
              <div class="text-body2">{{ event.hhf ? 'Ja' : 'Nej' }}</div>
            </div>

            <div
              class="q-mr-xl"
              v-if="
                projectDependentInputFields.errorRemedy && event.errorRemedy
              "
            >
              <div class="text-caption text-grey-7">
                Åtgärd vid felavhjälpning
              </div>
              <div class="text-body2">{{ event.errorRemedy.name }}</div>
            </div>

            <div
              class="q-mr-xl"
              v-if="
                event.reason.reason1 === 'Fordonsskada' &&
                can('event.penaltyAssessment')
              "
            >
              <div class="text-caption text-grey-7">OEU</div>
              <div class="text-body2">
                {{
                  event.penaltyAssessment === null
                    ? 'Saknas'
                    : event.penaltyAssessment.penalty
                    ? 'Ja'
                    : 'Nej'
                }}
              </div>
            </div>
          </div>

          <div
            class="row q-px-md q-py-sm full-width"
            v-if="event.dailyReportEvents.length"
          >
            <div class="q-mr-xl full-width" ref="observeExpansion">
              <div class="text-caption text-grey-7">
                Information från Dygnsrapport
              </div>
              <q-list bordered separator dense class="full-width">
                <q-expansion-item
                  dense
                  switch-toggle-side
                  class="full-width"
                  :key="$i"
                  v-for="(item, $i) of event.dailyReportEvents"
                >
                  <template #header="{ expanded }">
                    <div class="flex items-center">
                      <strong class="q-mr-md">
                        {{ item.from.substring(0, 5) }} -
                        {{ item.to.substring(0, 5) }}
                      </strong>
                      <div
                        class="ellipsis"
                        style="
                          max-width: calc(100vw - 300px);
                          white-space: nowrap;
                        "
                      >
                        {{ expanded ? '' : item.description }}
                      </div>
                    </div>
                  </template>
                  <q-card class="full-width">
                    <q-card-section>
                      <div class="text-body2" style="white-space: pre-wrap">
                        {{ item.description }}
                      </div>
                    </q-card-section>
                  </q-card>
                </q-expansion-item>
              </q-list>
            </div>
          </div>
        </div>

        <div class="row shadow-2">
          <q-tabs v-model="activeTab" class="text-secondary">
            <q-tab
              :name="tab.name"
              :label="tab.label"
              v-for="tab in tabs"
              :key="tab.name"
              @click="() => onClickTab(tab.name)"
            >
              <q-badge
                v-if="event[tab.prop].length"
                color="secondary"
                rounded
                floating
                >{{ event[tab.prop].length }}</q-badge
              >
            </q-tab>
          </q-tabs>
        </div>

        <q-tab-panels v-model="activeTab" animated ref="tabpanels">
          <q-tab-panel name="deviationVehicle" class="q-pa-none">
            <DeviationVehicleTable
              :deviation-vehicles="deviationVehicles"
              :loading="loadingDeviationVehicleProposals.getAll"
              :event="event"
              :height="tableHeight"
            >
              <template v-slot:top-left-buttons>
                <q-btn
                  v-if="can('deviationManagement.deviationVehicle.create')"
                  icon-right="mdi-plus-circle-outline"
                  label="Lägg till"
                  color="grey-7"
                  outline
                  @click="
                    () =>
                      deviationVehicleFormModal.openModal({ mode: 'create' })
                  "
                />
              </template>
              <template #top-right-extra>
                <q-toggle
                  v-model="showProposals"
                  label="Visa förslag"
                  class="q-mr-md"
                />
              </template>
            </DeviationVehicleTable>
          </q-tab-panel>

          <q-tab-panel name="deviationCancel" class="q-pa-none">
            <DeviationCancelTable
              :deviation-cancels="deviationCancels"
              :loading="loadingDeviationCancelProposals.getAll"
              :event="event"
              :height="tableHeight"
            >
              <template v-slot:top-left-buttons>
                <q-btn
                  v-if="can('deviationManagement.deviationCancel.create')"
                  icon-right="mdi-plus-circle-outline"
                  label="Lägg till"
                  color="grey-7"
                  outline
                  @click="
                    () => deviationCancelFormModal.openModal({ mode: 'create' })
                  "
                />
              </template>
              <template #top-right-extra>
                <q-toggle
                  v-model="showProposals"
                  label="Visa förslag"
                  class="q-mr-md"
                />
              </template>
            </DeviationCancelTable>
          </q-tab-panel>

          <q-tab-panel name="deviationDelay" class="q-pa-none">
            <DeviationDelayTable
              :deviation-delays="deviationDelays"
              :loading="loadingDeviationDelayProposals.getAll"
              :event="event"
              :height="tableHeight"
            >
              <template v-slot:top-left-buttons>
                <q-btn
                  v-if="can('deviationManagement.deviationDelay.create')"
                  icon-right="mdi-plus-circle-outline"
                  label="Lägg till"
                  color="grey-7"
                  outline
                  @click="
                    () => deviationDelayFormModal.openModal({ mode: 'create' })
                  "
                />
              </template>
              <template #top-right-extra>
                <q-toggle
                  v-model="showProposals"
                  label="Visa förslag"
                  class="q-mr-md"
                />

                <q-btn-dropdown
                  label="Filter banor"
                  class="q-mx-md"
                  outline
                  color="grey-7"
                >
                  <template #label>
                    <q-badge color="primary" floating>
                      {{ filterTrackCount }}
                    </q-badge>
                  </template>
                  <q-list dense>
                    <q-item-label class="q-py-xs bg-grey-2" header
                      >Avmarkera/markera alla</q-item-label
                    >
                    <q-separator />
                    <q-item clickable tag="label">
                      <q-item-section avatar>
                        <q-checkbox
                          :indeterminate-value="null"
                          :model-value="tracksSelected"
                          @update:model-value="(v) => onTrackGroupUpdate(v)"
                        />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>Markera alla banor</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item-label class="q-py-xs bg-grey-2" header
                      >Banor</q-item-label
                    >
                    <q-separator />
                    <q-item
                      tag="label"
                      clickable
                      v-for="(col, $i) of tracks"
                      :key="$i"
                    >
                      <q-item-section avatar>
                        <q-checkbox v-model="col.visible" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{ col.label }}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
              </template>
            </DeviationDelayTable>
          </q-tab-panel>

          <q-tab-panel name="deviationCrew" class="q-pa-none">
            <DeviationCrewTable
              :deviation-crews="event.deviationCrews"
              :height="tableHeight"
            >
              <template v-slot:top-left-buttons>
                <q-btn
                  v-if="can('deviationManagement.deviationCrew.create')"
                  icon-right="mdi-plus-circle-outline"
                  label="Lägg till"
                  color="grey-7"
                  outline
                  @click="
                    () => deviationCrewFormModal.openModal({ mode: 'create' })
                  "
                />
              </template>
            </DeviationCrewTable>
          </q-tab-panel>

          <q-tab-panel name="deviationDisruption" class="q-pa-none">
            <DeviationDisruptionTable
              :deviation-disruptions="event.deviationDisruptions"
              :height="tableHeight"
            >
              <template v-slot:top-left-buttons>
                <q-btn
                  v-if="can('deviationManagement.deviationDisruption.create')"
                  icon-right="mdi-plus-circle-outline"
                  label="Lägg till"
                  color="grey-7"
                  outline
                  @click="
                    () =>
                      deviationDisruptionFormModal.openModal({ mode: 'create' })
                  "
                />
              </template>
            </DeviationDisruptionTable>
          </q-tab-panel>
        </q-tab-panels>
      </div>
      <div
        v-else
        style="min-height: inherit"
        class="full-width items-center justify-center flex full-height"
      >
        <q-spinner color="secondary" size="10em" :thickness="1" />
      </div>
    </transition>
  </q-page>
</template>

<script lang="ts">
import { useEvent } from '@/composable/useEvent'
import format from 'date-fns/format'
import {
  computed,
  defineComponent,
  onBeforeMount,
  watch,
  provide,
  ref,
  onUnmounted,
} from 'vue'
import { useRoute } from 'vue-router'
import { useModal } from '@/composable/useModal'
import DeviationVehicleTable from '@/components/deviation/deviation-vehicle/DeviationVehicleTable.vue'
import DeviationDelayTable from '@/components/deviation/deviation-delay/DeviationDelayTable.vue'
import DeviationCancelTable from '@/components/deviation/deviation-cancel/DeviationCancelTable.vue'
import DeviationCrewTable from '@/components/deviation/deviation-crew/DeviationCrewTable.vue'
import DeviationDisruptionTable from '@/components/deviation/deviation-disruption/DeviationDisruptionTable.vue'
import DeviationVehicleFormModal from '@/components/deviation/deviation-vehicle/DeviationVehicleFormModal.vue'
import DeviationDelayFormModal from '@/components/deviation/deviation-delay/DeviationDelayFormModal.vue'
import DeviationCancelFormModal from '@/components/deviation/deviation-cancel/DeviationCancelFormModal.vue'
import DeviationCrewFormModal from '@/components/deviation/deviation-crew/DeviationCrewFormModal.vue'
import DeviationDisruptionFormModal from '@/components/deviation/deviation-disruption/DeviationDisruptionFormModal.vue'
import DeviationDeleteModal from '@/components/deviation/DeviationDeleteModal.vue'
import { useProfile } from '@/composable/useProfile'
import EventRowActionsDropdown from '@/components/event/EventRowActionsDropdown.vue'
import EventFormModal from '@/components/event/EventFormModal.vue'
import EventDeleteModal from '@/components/event/EventDeleteModal.vue'
import EventCloseModal from '@/components/event/EventCloseModal.vue'
import EventOpenModal from '@/components/event/EventOpenModal.vue'
import EventFordModal from '@/components/event/EventFordModal.vue'
import { routerPush } from '@/router'
import { CausingVehicle } from '@/types/causing-vehicle'
import { getVehicleDefect } from '@/api/vehicle-defect/getVehicleDefect'
import EventPenaltyAssessmentModal from '@/components/event/EventPenaltyAssessmentModal.vue'
import { useXod } from '@/composable/useXod'
import { useDeviationVehicleProposal } from '@/composable/useDeviationVehicleProposal'
import { useDeviationCancelProposal } from '@/composable/useDeviationCancelProposal'
import { useDeviationDelayProposal } from '@/composable/useDeviationDelayProposal'
import { QTabPanel } from 'quasar'
import { addDays, areIntervalsOverlapping } from 'date-fns'
import { getMpkEvent } from '@/api/mpk/getMpkEvent'
import DeviationDelayMPKModal from '@/components/deviation/deviation-delay/DeviationDelayMPKModal.vue'
import orderBy from 'lodash.orderby'
import { getTracks } from '@/api/lupp/getTracks'
import { LuppTrack } from '@/types/lupp-track'
import { useUserSettings } from '@/composable/useUserSettings'
import { socket } from '@/services/socket'

export default defineComponent({
  name: 'Event',

  components: {
    DeviationVehicleTable,
    DeviationDelayTable,
    DeviationCancelTable,
    DeviationCrewTable,
    DeviationDisruptionTable,
    DeviationVehicleFormModal,
    DeviationDelayFormModal,
    DeviationCancelFormModal,
    DeviationCrewFormModal,
    DeviationDisruptionFormModal,
    DeviationDeleteModal,
    EventRowActionsDropdown,
    EventFormModal,
    EventCloseModal,
    EventDeleteModal,
    EventOpenModal,
    EventFordModal,
    EventPenaltyAssessmentModal,
    DeviationDelayMPKModal,
  },

  setup() {
    const deviationVehicleFormModal = useModal()
    const deviationDelayFormModal = useModal()
    const deviationCancelFormModal = useModal()
    const deviationCrewFormModal = useModal()
    const deviationVehicleDeleteModal = useModal()
    const deviationDisruptionFormModal = useModal()
    const route = useRoute()
    const { can, projectDependentInputFields, currentProject } = useProfile()
    const { data: xodData, loading: loadingXod, search: searchXod } = useXod()
    const formModal = useModal()
    const deleteModal = useModal()
    const closeModal = useModal()
    const openModal = useModal()
    const fordModal = useModal()
    const penaltyAssessmentModal = useModal()
    const showProposals = ref(true)
    const tabpanels = ref<InstanceType<typeof QTabPanel> | null>(null)
    const tableHeight = ref('100%')
    const observeExpansion = ref<HTMLDivElement | null>(null)
    const project = currentProject.value?.name as string
    const {
      fetchAll: fetchAllDeviationVehicleProposals,
      data: deviationVehicleProposals,
      loading: loadingDeviationVehicleProposals,
    } = useDeviationVehicleProposal()

    const {
      fetchAll: fetchAllDeviationCancelProposals,
      data: deviationCancelProposals,
      loading: loadingDeviationCancelProposals,
    } = useDeviationCancelProposal()

    const {
      fetchAll: fetchAllDeviationDelayProposals,
      data: deviationDelayProposals,
      loading: loadingDeviationDelayProposals,
    } = useDeviationDelayProposal()

    onBeforeMount(() => {
      deviationVehicleProposals.value = []
      deviationCancelProposals.value = []
      deviationDelayProposals.value = []
    })

    socket.instance?.on(
      'delay-import-notification',
      (data: { startDate: string; endDate: string; project: string }) => {
        if (data.project !== currentProject.value?.name) return
        const period = proposalPeriod(eventPeriod.value)
        const overlapping = areIntervalsOverlapping(
          {
            start: new Date(period.from),
            end: new Date(period.to),
          },
          { start: new Date(data.startDate), end: new Date(data.endDate) }
        )

        if (!overlapping) return

        fetchAllDeviationDelayProposals(period)
      }
    )

    provide('ford-modal', fordModal)
    provide('form-modal', formModal)
    provide('delete-modal', deleteModal)
    provide('close-modal', closeModal)
    provide('open-modal', openModal)
    provide('deviation-vehicle-form-modal', deviationVehicleFormModal)
    provide('deviation-delay-form-modal', deviationDelayFormModal)
    provide('deviation-cancel-form-modal', deviationCancelFormModal)
    provide('deviation-crew-form-modal', deviationCrewFormModal)
    provide('deviation-disruption-form-modal', deviationDisruptionFormModal)

    provide('deviation-delete-modal', deviationVehicleDeleteModal)

    provide('penalty-assessment-modal', penaltyAssessmentModal)

    const tabs = computed(() => {
      return [
        {
          label: 'Inställt tåg',
          name: 'deviationCancel',
          prop: 'deviationCancels',
          permission: 'deviationManagement.deviationCancel.list',
        },
        {
          label: 'Fordonsavvikelse',
          name: 'deviationVehicle',
          prop: 'deviationVehicles',
          permission: 'deviationManagement.deviationVehicle.list',
        },
        {
          label: 'Förseningar',
          name: 'deviationDelay',
          prop: 'deviationDelays',
          permission: 'deviationManagement.deviationDelay.list',
        },
        {
          label: 'Bemanning',
          name: 'deviationCrew',
          prop: 'deviationCrews',
          permission: 'deviationManagement.deviationCrew.list',
        },
        {
          label: 'Störningshantering',
          name: 'deviationDisruption',
          prop: 'deviationDisruptions',
          permission: 'deviationManagement.deviationDisruption.list',
        },
      ]
        .filter((tabItem) => can(tabItem.permission))
        .filter((x) => {
          if (x.name === 'deviationDisruption') {
            return project !== 'gotalandstag'
          }
          return true
        })
    })
    const {
      data: event,
      fetch: fetchEvent,
      loading: loadingStateEvents,
    } = useEvent()
    const { settings } = useUserSettings()
    const eventUuid = route.params.eventUuid as string
    const activeTab = ref(
      settings.value[project].event.activeEventTab ||
        [
          'deviationCancel',
          'deviationVehicle',
          'deviationDelay',
          'deviationCrew',
          'deviationDisruption',
        ].find((deviationType) =>
          can(`deviationManagement.${deviationType}.list`)
        )
    )

    watch(
      () => activeTab.value,
      (value) => {
        if (value) {
          settings.value[project].event.activeEventTab = value
        }
      }
    )

    const loadingTrvIds = ref(true)
    const trvValidIds = ref<{ [key: string]: boolean }>({})

    watch(
      () => event.value,
      async () => {
        loadingTrvIds.value = true
        const trvIds = event.value?.eventTrvs || []
        for (const eventId of trvIds) {
          const res = await getMpkEvent(eventId.trvId)
          if (res.data) {
            trvValidIds.value[eventId.trvId] = true
          }
        }
        loadingTrvIds.value = false
      },
      {
        immediate: true,
      }
    )

    const badgeColor = computed(() => (event.value?.end ? 'grey-5' : 'primary'))

    function formatDate(date: string) {
      return format(new Date(date), 'yyyy-MM-dd')
    }

    function onDeleteEvent() {
      routerPush(`events`)
    }

    function onClickFordChip(causingVehicle: CausingVehicle) {
      fordModal?.openModal({
        cb: async (setData) => {
          const defectNumber = causingVehicle.ford as number
          await getVehicleDefect(defectNumber)
            .then(({ data }) => {
              setData(data)
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    function onClickLinkedEvent() {
      if (!event.value || !event.value.linkedEventUuid) return
      routerPush('event', {
        eventUuid: event.value.linkedEventUuid,
      })
    }

    const xodColumnValue = (xodId: number) => {
      const xod = xodData.value.find((x) => xodId === x.trafficDisturbanceId)
      if (xod) {
        return xod.title
      }

      return 'Xod titel hittades inte'
    }

    const deviationVehicles = computed(() => {
      const data = event.value?.deviationVehicles || []
      if (!showProposals.value) return data
      return [...deviationVehicleProposals.value, ...data]
    })

    const deviationCancels = computed(() => {
      const data = event.value?.deviationCancels || []
      if (!showProposals.value) return data
      return [...deviationCancelProposals.value, ...data]
    })

    const deviationDelays = computed(() => {
      const data = event.value?.deviationDelays || []
      if (!showProposals.value) return data
      return [...deviationDelayProposals.value, ...data].filter((x) => {
        return tracks.value.some((t) => t.label === x.bana && t.visible)
      })
    })

    const eventPeriod = ref({
      from: '',
      to: '',
    })

    const resizeTable = () => {
      if (tabpanels.value) {
        const rect = tabpanels.value.$el.getBoundingClientRect()
        tableHeight.value = `${window.innerHeight - rect.top}px`
      }
    }

    const proposalPeriod = (period: { from: string; to: string }) => {
      return {
        from: period.from,
        to: format(addDays(new Date(period.from), 2), 'yyyy-MM-dd'),
      }
    }

    const init = async () => {
      const data = await fetchEvent(eventUuid)
      eventPeriod.value = {
        from: format(new Date(data.start), 'yyyy-MM-dd'),
        to: format(new Date(data.start), 'yyyy-MM-dd'),
      }
      if (activeTab.value) {
        await onClickTab(activeTab.value)
      }

      resizeTable()
    }

    const onClickTab = async (tabName: string) => {
      if (tabName === 'deviationCancel') {
        fetchAllDeviationCancelProposals(proposalPeriod(eventPeriod.value))
      }

      if (tabName === 'deviationVehicle') {
        fetchAllDeviationVehicleProposals(proposalPeriod(eventPeriod.value))
      }

      if (tabName === 'deviationDelay') {
        await fetchAllDeviationDelayProposals(proposalPeriod(eventPeriod.value))
      }
    }

    window.addEventListener('resize', resizeTable)

    const resizeObserver = new ResizeObserver(() => {
      resizeTable()
    })

    watch(
      () => observeExpansion.value,
      () => {
        if (observeExpansion.value) {
          resizeObserver.observe(observeExpansion.value)
        }
      }
    )

    onUnmounted(() => {
      window.removeEventListener('resize', resizeTable)
      resizeObserver.disconnect()
    })

    const mpkModal = useModal()
    provide('delay-mpk-modal', mpkModal)

    function onClickMpkChip(mpk_event_id: string) {
      const eventId = mpk_event_id.split(' ')[0].replace('H', '')
      mpkModal?.openModal({
        cb: async (setData) => {
          await getMpkEvent(eventId)
            .then(({ data }) => {
              setData({
                eventId,
                mpkData: data,
              })
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    interface TrackItem {
      label: string
      visible: boolean
    }
    const tracks = ref<TrackItem[]>([])
    const tracksSelected = ref<boolean | null>(false)

    const localstorageBanor = settings.value[project].delay.tracks

    const setBanor = (luppTracks: LuppTrack[]) => {
      const banor = orderBy(
        [...new Set(luppTracks.map((x) => x.description))],
        (x) => x,
        ['asc']
      )
      tracks.value = banor
        .map((label) => {
          const bana = localstorageBanor.find((x) => x.label === label)
          return {
            label,
            visible: bana ? bana.visible : true,
          }
        })
        .filter((x) => x.label !== 'Göteborg – Halmstad')
    }

    const fetchTracks = async () => {
      const { data } = await getTracks()
      setBanor(data)
    }

    fetchTracks()

    watch(
      () => tracks.value,
      (value) => {
        if (value.every((x) => x.visible)) {
          tracksSelected.value = true
        } else if (value.every((x) => !x.visible)) {
          tracksSelected.value = false
        } else {
          tracksSelected.value = null
        }
      },
      {
        deep: true,
        immediate: true,
      }
    )

    const onTrackGroupUpdate = (value: boolean) => {
      tracks.value.forEach((x) => {
        x.visible = value
      })
    }

    const filterTrackCount = computed(() => {
      return tracks.value.filter((x) => x.visible).length
    })

    watch(
      () => tracks.value,
      (value) => {
        settings.value[project].delay.tracks = value
      }
    )

    init()

    return {
      onClickTab,
      tabs,
      eventUuid,
      formatDate,
      tableHeight,
      activeTab,
      event,
      badgeColor,
      loadingStateEvents,
      deviationVehicleFormModal,
      deviationDelayFormModal,
      deviationCancelFormModal,
      deviationCrewFormModal,
      deviationVehicleDeleteModal,
      deviationDisruptionFormModal,
      can,
      formModal,
      deleteModal,
      closeModal,
      openModal,
      onDeleteEvent,
      fordModal,
      onClickFordChip,
      projectDependentInputFields,
      onClickLinkedEvent,
      penaltyAssessmentModal,
      searchXod,
      loadingXod,
      xodColumnValue,
      deviationVehicles,
      deviationCancels,
      deviationDelays,
      loadingDeviationVehicleProposals,
      loadingDeviationCancelProposals,
      loadingDeviationDelayProposals,
      showProposals,
      tabpanels,
      observeExpansion,
      onClickMpkChip,
      mpkModal,
      trvValidIds,
      loadingTrvIds,
      tracksSelected,
      filterTrackCount,
      tracks,
      onTrackGroupUpdate,
    }
  },
})
</script>
